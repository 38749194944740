//@ts-check

//SHARED
const sharedState = (state) => state.shared;
const authError = (state) => sharedState(state).authError;
const authMessage = (state) => sharedState(state).authMessage;
const isAuthorized = (state) => sharedState(state).authorized;
const prompt = (state) => sharedState(state).prompt;
const isLoading = (state) => sharedState(state).isLoading;
const isLoadingHided = (state) => sharedState(state).isLoadingHided;

export const sharedSelectors = {
    isAuthorized,
    authError,
    authMessage,
    prompt,
    isLoading,
    isLoadingHided,
};

//PROFILE
const profileState = (state) => state.profile;
const getProfile = (state) => profileState(state).profile;

export const profileSelectors = {
    getProfile,
};

//CLUB
const clubState = (state) => state.club;
const getClubList = (state) => clubState(state).clubList;
const getClubData = (state) => clubState(state).clubData;

export const clubSelectors = {
    getClubList,
    getClubData,
};

//SETTINGS
const settingsState = (state) => state.settings;
const getSettings = (state) => settingsState(state).settings;

export const settingsSelectors = {
    getSettings,
};

//MEMBER
const memberState = (state) => state.member;
const getReservedPersonalNumbers = (state) => memberState(state).reservedPersonalNumbers;
const getRestoreByPersonalNumberData = (state) => memberState(state).restoreByPersonalNumberData;
const getRestoreByCommonInfoData = (state) => memberState(state).restoreByCommonInfoData;
const getGenerationProcessToken = (state) => memberState(state).generationProcessToken;
const getGenerationProcessData = (state) => memberState(state).generationProcessData;

export const memberSelectors = {
    getReservedPersonalNumbers,
    getRestoreByPersonalNumberData,
    getRestoreByCommonInfoData,
    getGenerationProcessToken,
    getGenerationProcessData,
};

//TOURNAMENT STATUS
const tournamentStatusState = (state) => state.tournamentStatus;
const getFullTournamentStatusList = (state) => tournamentStatusState(state).fullTournamentStatusList;

export const tournamentStatusSelectors = {
    getFullTournamentStatusList,
};

//TOURNAMENT
const tournamentState = (state) => state.tournament;
const getTournamentCache = (state) => tournamentState(state).cache;

export const tournamentSelectors = {
    getTournamentCache,
};

//TOURNAMENT ATTACHMENT
const tournamentAttachmentState = (state) => state.tournamentAttachment;
const getTournamentAttachmentCache = (state) => tournamentAttachmentState(state).cache;

export const tournamentAttachmentSelectors = {
    getTournamentAttachmentCache,
};

//TOURNAMENT MEMBER
const tournamentMemberState = (state) => state.tournamentMember;
const getTournamentMemberCache = (state) => tournamentMemberState(state).cache;

export const tournamentMemberSelectors = {
    getTournamentMemberCache,
};

//TOURNAMENT CATEGORY
const tournamentCategoryState = (state) => state.tournamentCategory;
const getTournamentCategoryCache = (state) => tournamentCategoryState(state).cache;

export const tournamentCategorySelectors = {
    getTournamentCategoryCache,
};

//TOURNAMENT GROUP
const tournamentGroupState = (state) => state.tournamentGroups;
const getTournamentGroupCache = (state) => tournamentGroupState(state).cache;

export const tournamentGroupSelectors = {
    getTournamentGroupCache,
};

//TOURNAMENT DIVISION
const tournamentDivisionState = (state) => state.tournamentDivisions;
const getTournamentDivisionCache = (state) => tournamentDivisionState(state).cache;

export const tournamentDivisionSelectors = {
    getTournamentDivisionCache,
};

//FILTERS
const filterState = (state) => state.filter;
const getUserFilter = (state) => filterState(state).usersFilter;
const getMemberFilter = (state) => filterState(state).membersFilter;
const getTournamentFilter = (state) => filterState(state).tournamentsFilter;
const getClubFilter = (state) => filterState(state).clubsFilter;
const getMotionsFilter = (state) => filterState(state).motionsFilter;
const getOrganizationsFilter = (state) => filterState(state).organizationsFilter;

export const filterSelectors = {
    getUserFilter,
    getMemberFilter,
    getTournamentFilter,
    getClubFilter,
    getMotionsFilter,
    getOrganizationsFilter,
};

//TOURNAMENT NODE

/**
    @typedef {import("../../Shared/helpers/api.tournamentNodes").TournamentNodeGetRequest} TournamentNodeGetRequest
    @typedef {import("../../Shared/helpers/api.tournamentNodes").TournamentNodeGetResponse} TournamentNodeGetReponse
*/

/**
    @typedef TournamentNodeCacheItem
    @prop {TournamentNodeGetReponse} response
    @prop {TournamentNodeGetRequest} request

    @typedef {TournamentNodeCacheItem[]} TournamentNodeCache
*/

const tournamentNodeState = (state) => state.tournamentNodes;
const getTournamentNodeCache = (state) => /**@type {TournamentNodeCache} */ (tournamentNodeState(state).cache);

export const tournamentNodeSelectors = {
    getTournamentNodeCache,
};

//TOURNAMENT NODE MEMBER

/**
    @typedef {import("../../Shared/helpers/api.tournamentNodeMembers").TournamentNodeMemberGetRequest} TournamentNodeMemberGetRequest
    @typedef {import("../../Shared/helpers/api.tournamentNodeMembers").TournamentNodeMemberGetResponse} TournamentNodeMemberGetResponse
*/

/**
    @typedef TournamentNodeMemberCacheItem
    @prop {TournamentNodeMemberGetRequest} request
    @prop {TournamentNodeMemberGetResponse} response

    @typedef {TournamentNodeMemberCacheItem[]} TournamentNodeMemberCache
*/

const tournamentNodeMemberState = (state) => state.tournamentNodeMembers;
const getTournamentNodeMemberCache = (state) =>
    /**@type {TournamentNodeMemberCache} */ (tournamentNodeMemberState(state).cache);

export const tournamentNodeMemberSelectors = {
    getTournamentNodeMemberCache,
};

//TOURNAMENT CARPET

/**
    @typedef {import("../../Shared/helpers/api.tournamentCarpets").TournamentCarpetGetRequest} TournamentCarpetGetRequest
    @typedef {import("../../Shared/helpers/api.tournamentCarpets").TournamentCarpetGetResponse} TournamentCarpetGetResponse
*/

/**
    @typedef TournamentCarpetCacheItem
    @prop {TournamentCarpetGetRequest} request
    @prop {TournamentCarpetGetResponse} response

    @typedef {TournamentCarpetCacheItem[]} TournamentCarpetCache
*/

const tournamentCarpetState = (state) => state.tournamentCarpets;
const getTournamentCarpetCache = (state) => /**@type {TournamentCarpetCache} */ (tournamentCarpetState(state).cache);

export const tournamentCarpetSelectors = {
    getTournamentCarpetCache,
};

//PUBLIC
const publicState = (state) => state.public;
const getPublicData = (state) => publicState(state).data;

export const publicSelectors = {
    getPublicData,
};

//LOG
const logState = (state) => state.log;
const logList = (state) => logState(state).logList;
const logData = (state) => logState(state).logData;

export const logSelectors = {
    logList,
    logData,
};
