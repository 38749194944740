import React from "react";
import styles from "../LoginContainer.module.scss";
import Input from "@Admin/components/Input/Input";
import Checkbox from "@Admin/components/Checkbox/Checkbox";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { SubmitHandler, useForm } from "react-hook-form";
import { emailMaxLength, getTextFieldValidation, newerEmailRegExp } from "@Shared/helpers/validation";
import { isNil } from "lodash";
import { Button } from "@mui/material";

interface LoginFormFields {
    email: string | null;
    password: string | null;
    rememberMe: boolean;
}

interface LoginFormProps {
    isLoading: boolean;
    authMessage?: string | null;
    authError?: string | null;
    resetAuthError?: () => (dispatch: any) => void;
    loginAction?: (data: any) => (dispatch: any) => void;
}

const LoginForm = (props: LoginFormProps) => {
    const { isLoading, resetAuthError, loginAction, authError, authMessage } = props;
    const navigate = useNavigate();
    const { t } = useTranslation();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<LoginFormFields>({
        mode: "all",
        defaultValues: {
            email: null,
            password: null,
            rememberMe: false,
        },
    });

    const onLoginSubmit: SubmitHandler<LoginFormFields> = (data: LoginFormFields) => {
        resetAuthError?.();
        loginAction?.({
            passwordParameters: {
                email: data.email,
                password: data.password,
                remember: data.rememberMe,
            },
        });
    };

    return (
        <>
            <div className={styles.formHeader}>
                <h2 className={styles.title}>{t("auth.login.authorization")}</h2>
            </div>

            <div className={styles.inputs}>
                <Input
                    placeholder={t("auth.login.placeholder.email")}
                    name={"email"}
                    inputProps={{
                        ...register("email", {
                            ...getTextFieldValidation(true, newerEmailRegExp, 3, emailMaxLength),
                        }),
                    }}
                    error={!isNil(errors.email)}
                    errorText={errors.email?.message}
                />
                <Input
                    placeholder={t("auth.login.placeholder.password")}
                    type="password"
                    inputProps={{
                        ...register("password", {
                            ...getTextFieldValidation(true, null, 5, 50),
                        }),
                    }}
                    error={!isNil(errors.password)}
                    errorText={errors.password?.message}
                />
            </div>
            {(!isNil(authError) || !isNil(authMessage)) && (
                <p className={!isNil(authError) ? styles.authError : styles.authMessage}>{authError || authMessage}</p>
            )}
            <div className={styles.checkboxWrapper}>
                <Checkbox
                    label={t("auth.login.rememberMe")}
                    name={"rememberMe"}
                    inputProps={{
                        ...register("rememberMe"),
                    }}
                />
                <div>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            resetAuthError?.();
                            navigate("/login/restore");
                        }}
                    >
                        {t("auth.login.forgotPassword")}
                    </Button>
                </div>
            </div>
            <div className={styles.footerBtn}>
                <Button variant="contained" type="submit" disabled={isLoading} onClick={handleSubmit(onLoginSubmit)}>
                    {t("auth.login.login")}
                </Button>
            </div>
        </>
    );
};

export default LoginForm;
