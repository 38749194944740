import { isEmpty, isNil, isString, isUndefined, now } from "lodash";
import { matchIsValidTel } from "mui-tel-input";
import i18n from "@Admin/i18n";
import { nationalCodes } from "./countries";
import { DateType } from "./validationEnums";

export const validatePersonalNumber = (
    value: string | undefined,
    regionId: number | undefined,
    isAvailable: boolean | undefined,
) => {
    if (isEmpty(value) || isUndefined(value)) {
        return i18n.t("form.validationMessages.requiredField");
    }

    if (!personalNumberRegExp.test(value ?? "")) {
        return i18n.t("form.validationMessages.wrongFormat");
    }

    if (!regionId || !value.endsWith(regionId.toString())) {
        return i18n.t("form.validationMessages.wrongRegionCode");
    }

    if (!isAvailable) {
        return i18n.t("form.validationMessages.personalNumberIsTaken");
    }

    return undefined;
};

export const verifyPassword = (password?: string | null, comparePassword?: string | null) => {
    if (isEmpty(password)) return undefined;

    if (!passwordRegExp.test(password ?? "")) return i18n.t("form.validationMessages.invalidPasswordCharacters");

    if (isUndefined(comparePassword)) return undefined;

    if (password !== comparePassword) return i18n.t("form.validationMessages.passwordsAreNotMatched");
};

export const validationLength = (
    value: string,
    minLength: number | null,
    maxLength: number | null,
): string | undefined => {
    if (minLength !== null && minLength !== 0 && (isNil(value) || value.length < minLength)) {
        return i18n.t("form.validationMessages.minLength", { count: minLength });
    }

    if (maxLength !== null && value?.length > maxLength) {
        return i18n.t("form.validationMessages.maxLength", { count: maxLength });
    }

    return;
};

export const validationSelected = (value: string, includes: string[]): string | undefined => {
    if (includes.includes(value) === false) {
        return i18n.t("form.validationMessages.notChosen");
    }

    return;
};

export const validationFormDatePeriodString = (type) => (from: string | null, to: string | null) => {
    try {
        const fromDate = from && Number(from);
        const toDate = to && Number(to);

        if (fromDate && fromDate < 1900) {
            return i18n.t("form.validationMessages.minYearValue");
        }

        if (fromDate && toDate && fromDate > toDate) {
            if (type === `from`) {
                return i18n.t("form.validationMessages.biggerThanFrom");
            }
            if (type === `to`) {
                return i18n.t("form.validationMessages.smallerThanTo");
            }
        }
    } catch (e) {
        console.log(e);
    }
};

export const validationFormDatePeriod = (type: DateType, skipUpToToday?: boolean) => (from, to) => {
    try {
        const fromDate = from && new Date(from);
        const toDate = to && new Date(to);
        if ((type === DateType.From && isNil(from)) || (type === DateType.To && isNil(to))) {
            return i18n.t("form.validationMessages.wrongFormat");
        }

        if (!skipUpToToday) {
            const todayDate = new Date(now());
            if (fromDate > todayDate || toDate > todayDate) {
                return i18n.t(`form.validationMessages.maxAllowedDate`, {
                    date: todayDate.toLocaleDateString("ru-RU"),
                });
            }
        }

        if (fromDate && toDate && fromDate > toDate) {
            if (type === DateType.From) {
                return i18n.t(`form.validationMessages.maxAllowedDate`, { date: toDate.toLocaleDateString("ru-RU") });
            }
            if (type === DateType.To) {
                return i18n.t(`form.validationMessages.minAllowedDate`, { date: fromDate.toLocaleDateString("ru-RU") });
            }
        }
    } catch (e) {
        console.log(e);
    }
};

export const validationEmail = (value?: string | null, canEmpty = false) => {
    if (canEmpty && (isNil(value) || isEmpty(value))) {
        return undefined;
    }

    if (isNil(value) || isEmpty(value)) return i18n.t("form.validationMessages.requiredField");

    const re = new RegExp(
        /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-||_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/gi,
    );
    if (!re.test(value)) {
        return i18n.t("form.validationMessages.enterValidEmailAddress");
    }

    return undefined;
};

export const validateIsEmpty = function (
    value: string | number | undefined | null,
    setState: (val: any) => void,
): boolean {
    if ((isString(value) && isEmpty(value)) || isNil(value)) {
        setState(i18n.t("form.validationMessages.requiredField"));
        return false;
    }

    setState(undefined);
    return true;
};

export const validateDoB = (value: Date | null | undefined, required = true) => {
    if (isNil(value) && !required) {
        return undefined;
    }

    if (isNil(value)) {
        return i18n.t("form.validationMessages.requiredField");
    }

    if (value >= new Date()) {
        return i18n.t("form.validationMessages.wrongFormat");
    }

    return undefined;
};

export const validateEmail = (value: string, isAvailable: boolean | undefined, canEmpty = false) => {
    if (canEmpty && (isNil(value) || isEmpty(value))) {
        return undefined;
    }

    if (isNil(value) || isEmpty(value)) {
        return i18n.t("form.validationMessages.requiredField");
    }

    if (!newerEmailRegExp.test(value)) {
        return i18n.t("form.validationMessages.wrongFormat");
    }

    if (!isAvailable) {
        return i18n.t("form.validationMessages.emailIsTaken");
    }

    return undefined;
};

export const getTextFieldValidation = (
    isRequired: boolean,
    pattern: RegExp | null,
    minLength: number | null,
    maxLength: number | null,
) => {
    const validation = {};
    if (!isNil(isRequired) && isRequired) {
        validation["required"] = {
            value: true,
            message: i18n.t("form.validationMessages.requiredField"),
        };
    }

    if (!isNil(pattern)) {
        validation["pattern"] = {
            value: pattern,
            message: i18n.t("form.validationMessages.wrongFormat"),
        };
    }

    if (!isNil(minLength) && minLength >= 0) {
        validation["minLength"] = {
            value: minLength,
            message: i18n.t("form.validationMessages.minLength", { count: minLength }),
        };
    }

    if (!isNil(maxLength) && maxLength > 0) {
        validation["maxLength"] = {
            value: maxLength,
            message: i18n.t("form.validationMessages.maxLength", { count: maxLength }),
        };
    }

    return validation;
};

export const getPhoneNumberValidation = (isRequired: boolean) => {
    const validation = {};
    const phoneMinLength = 10;
    const phoneMaxLength = 20;

    if (!isNil(isRequired) && isRequired) {
        validation["required"] = {
            value: true,
            message: i18n.t("form.validationMessages.requiredField"),
        };
    }

    validation["pattern"] = {
        value: phoneNumberRegExp,
        message: i18n.t("form.validationMessages.wrongFormat"),
    };

    validation["minLength"] = {
        value: phoneMinLength,
        message: i18n.t("form.validationMessages.minLength", { count: phoneMinLength }),
    };

    validation["maxLength"] = {
        value: phoneMaxLength,
        message: i18n.t("form.validationMessages.maxLength", { count: phoneMaxLength }),
    };

    return validation;
};

export const getSelectValidation = (isRequired: boolean) => {
    return {
        required: {
            value: isRequired,
            message: i18n.t("form.validationMessages.notChosen"),
        },
    };
};

export const emailRegExp =
    /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-||_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+([a-z]+|\d|-|\.{0,1}|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])?([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/gi;
export const personalNumberRegExp = /^БФТ\s\d\d\d\d-\d$/;
export const fullNamePartsRegExp = /^(?![ -])(([А-я -](?![ -][ -]))+)(?<![ -])$/i;
export const partPhoneMaxLength = 9;
export const newerEmailRegExp = /^[a-zA-Z0-9._-]{1,64}@[a-zA-Z0-9.-]{1,250}\.[a-zA-Z]{2,4}$/;
export const passwordRegExp = /^[A-Za-z\d\`\~\!\@\#\$\%\^\&\*\(\)\_\-\+\=\{\}\[\]\\\|\:\;\"\'\<\>\,\.\?\/]*$/;
export const passwordMinLength = 4;
export const emailMaxLength = 40;
export const phoneMaxLength = 20;
export const clubNameRegExp = /^(?![ -])(([А-я0-9 "'-](?![- ][- ])(?!["']["']))+)(?<![ -])$/i;
export const addressRegExp = /^[А-я ,0-9.]+$/i;
export const cityRegExp = /^[А-я ,0-9.]+$/i;
export const emailPattern = /^[a-zA-Z0-9@.]*$/;
export const phonePattern = /^\+?[0-9]*$/;

// stubs
export const fullNameRegExp = /.*/;
export const fullNameOrPersonalNumberRegExp = /.*/;
export const basicFilterRegExp = /.*/;

//export const fullNameRegExp = /^[а-яА-ЯёЁ]+([а-яА-ЯёЁ]+[-\s]?)*$/;
//export const fullNameOrPersonalNumberRegExp = /^(?:[а-яА-ЯёЁ]+([а-яА-ЯёЁ]+[-\s]?)*|\d{1,4}-?\d*)$/;
//export const basicFilterRegExp = /^[а-яА-ЯёЁ'"]+([а-яА-ЯёЁ«»'"№,.0-9]+[-\s]?)*$/;

const phoneNumberRegExp = /^(\+?(\d{1,3})?[- .]?\(?(?:\d{2,3})\)?[- .]?\d\d\d[- .]?\d\d\d\d)|(\+1)$/;
