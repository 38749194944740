import { createTheme } from "@mui/material";

declare module "@mui/material/styles" {
    interface BreakpointOverrides {
        xlm: true;
    }
}

const theme = createTheme({
    palette: {
        primary: {
            main: "#0D93F2",
        },
        secondary: {
            main: "#ACAFBF",
        },
        text: {
            primary: "#000",
        },
        success: {
            main: "#77B200",
            light: "#669900",
            dark: "#558000",
        },
        error: {
            main: "#FF2B00",
        },
    },
    typography: {
        fontFamily: "Source Sans Pro, sans-serif",
        body1: {
            fontSize: "16px",
            lineHeight: "20px",
        },
        body2: {
            fontSize: "20px",
            lineHeight: "32px",
        },
        button: {
            textTransform: "capitalize",
            fontWeight: 600,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState, theme }) => {
                    switch (ownerState.variant) {
                        case "contained":
                            return {
                                color: "#fff",
                                height: "35px",
                                textTransform: "none",
                                "&.Mui-disabled": {
                                    opacity: 0.6,
                                },
                            };
                        case "outlined":
                            return {
                                paddingBlock: "6px",
                                paddingInline: "8px",
                                border: `1px solid currentColor`,
                                height: "35px",
                                textTransform: "none",
                                "&.Mui-disabled": {
                                    opacity: 0.6,
                                    background: "transparent",
                                },
                            };
                        case "text":
                            return {
                                paddingBlock: "6px",
                                paddingInline: "8px",
                                textTransform: "none",
                                "&.Mui-disabled": {
                                    opacity: 0.6,
                                },
                            };
                        default:
                            return;
                    }
                },
                sizeMedium: {
                    fontSize: "15px",
                    lineHeight: "23px",
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    minHeight: "36px",
                    color: "#000",
                    textTransform: "none",
                    ":hover": {
                        color: "#0D93F2",
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    "& .Mui-selected": {
                        color: "#0D93F2",
                    },
                },
            },
            defaultProps: {
                TabIndicatorProps: {
                    sx: {
                        width: "3px",
                        left: 0,
                        right: "initial",
                    },
                },
            },
        },
    },
});

export default theme;
